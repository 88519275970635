import * as React from "react";
import { Link } from "gatsby";
import Layout from '../../components/Layout';
import ServicesHeaderImage from '../../svg/Services-Image.svg';
import OurProcessImage from '../../images/our-process.jpg';
import OurProcessImage2 from '../../images/our-process2.jpg';
import CardWebDesignImage from '../../images/card-web-design-image4.jpg';
import CardWebAppImage from '../../images/card-web-app-image2.jpg';
import CardDigitalMarketingImage from '../../images/card-digital-marketing-image3.jpg';
import CardBrandingImage from '../../images/card-branding-image3.jpg';
import DoctorVR from '../../images/doctor-vr.jpg';
import MedIcon1 from "../../images/medical-icons/1.png"
import MedIcon2 from "../../images/medical-icons/2.png"
import MedIcon3 from "../../images/medical-icons/3.png"
import MedIcon4 from "../../images/medical-icons/4.png"
import MedIcon5 from "../../images/medical-icons/5.png"
import MedIcon6 from "../../images/medical-icons/6.png"
import MedIcon7 from "../../images/medical-icons/7.png"
import MedIcon8 from "../../images/medical-icons/8.png"
import MedIcon9 from "../../images/medical-icons/9.png"
import MedIcon10 from "../../images/medical-icons/10.png"
import MedIcon11 from "../../images/medical-icons/11.png"
import MedIcon12 from "../../images/medical-icons/12.png"
import MedIcon13 from "../../images/medical-icons/13.png"
import MedIcon14 from "../../images/medical-icons/14.png"
import MedIcon15 from "../../images/medical-icons/15.png"
import MedIcon16 from "../../images/medical-icons/16.png"
import MedIcon17 from "../../images/medical-icons/17.png"
import MedIcon18 from "../../images/medical-icons/18.png"
import MedIcon19 from "../../images/medical-icons/19.png"
import MedIcon20 from "../../images/medical-icons/20.png"
import SEO from '../../components/Seo';
import CTA from "../../components/CTA";

const Services = () => {
   

    return(
        <Layout>
            <SEO title="Services" keywords="HealthOne Digital, healthcare website design, healthcare web application, healthcare branding, healthcare digital marketing"/>
            <div className="pt-10 bg-blue-100 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8">

                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                            <div className="lg:py-24">
                                <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                    <span className="block text-blue-900">Our <span className="text-blue-500">Services</span></span>
                                </h1>
                                <p className="mt-3 text-lg leading-8 text-blue-900 sm:mt-5 sm:text-xl lg:mt-10 lg:text-lg xl:text-xl">
                                    Succeeding in today's ever expanding digital health marketplace requires a combination of services to work in unison. From branding to web design to digital marketing, we ensure that you meet your objectives in a timely and cost effective manner.
                                </p>
                                <div className="mt-10">
                                    <Link to="/contact-us" className="inline-flex px-5 py-3 border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-blue-900 hover:text-white bg-gradient-to-r from-white to-white hover:from-blue-700 hover:to-blue-700">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="lg:-mt-10 lg:relative">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            <ServicesHeaderImage className="w-full h-auto lg:absolute lg:inset-y-0" alt=""/>
                        </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* SERVICES OVERVIEW*/}
            <section>
                <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                    <div className="absolute inset-0">
                        <div className="bg-gray-50 h-1/3 sm:h-2/3"></div>
                    </div>

                    <div className="relative max-w-7xl mx-auto">
                        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

                        {/* <div className="invisible lg:visible flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-1 bg-blue-900 p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                    <p className="mt-2 text-3xl lg:text-5xl font-semibold text-white">
                                        Build
                                    </p>
                                </div>
                            </div>
                        </div> */}

                        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={CardWebDesignImage} alt=""/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div className="flex-1">
                                <p className="text-sm font-medium text-blue-600">
                                    Services
                                </p>
                                <p className="mt-2 text-xl font-semibold text-gray-900">
                                    Website Design & Development
                                </p>
                                <p className="mt-3 text-base text-gray-500">
                                    A Fast, Mobile Optimized, Secure and Accessible website is imperative for the sucess of your Digital Marketing efforts.
                                </p>
                                <Link to="/services/website-design" className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                    Learn More
                                </Link>
                            </div>
                            </div>
                        </div>

                        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={CardDigitalMarketingImage} alt=""/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div className="flex-1">
                                <p className="text-sm font-medium text-blue-600">
                                    Services
                                </p>
                                <p className="mt-2 text-xl font-semibold text-gray-900">
                                    Digital Marketing
                                </p>
                                <p className="mt-3 text-base text-gray-500">
                                Your practice should appear on the first page of search results if someone searches for you. We can help you achieve that.
                                </p>
                                <Link to="/services/digital-marketing" className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                    Learn More
                                </Link>
                            </div>
                            
                            </div>
                        </div>

                        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={CardBrandingImage} alt=""/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div className="flex-1">
                                <p className="text-sm font-medium text-blue-600">
                                    Services
                                </p>
                                <p className="mt-2 text-xl font-semibold text-gray-900">
                                    Branding Design
                                </p>
                                <p className="mt-3 text-base text-gray-500">
                                    A strong brand is important to build patient trust and stand out in an increasingly competitive marketplace.
                                </p>
                            </div>
                            <Link to="/services/branding-design" className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                Learn More
                            </Link>
                            </div>
                        </div>
                        </div>
                    </div>

                        {/* <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={CardWebAppImage} alt=""/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div className="flex-1">
                                <p className="text-sm font-medium text-blue-600">
                                    Services
                                </p>
                                <p className="mt-2 text-xl font-semibold text-gray-900">
                                    Web Application Development
                                </p>
                                <p className="mt-3 text-base text-gray-500">
                                    When you can't find an existing solution or have a very particular use case, we can design & develop web applications specifically for your needs.
                                </p>
                                <Link to="/services/web-app-development" className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                    Learn More
                                </Link>
                            </div>
                            
                            </div>
                        </div> */}

                      

    
                </div>
            </section>

       

            {/* CTA */}
            <CTA/>

        </Layout>
        
    )
}

export default Services;